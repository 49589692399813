html, body, #root, .App {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
}

.picker-wrapper {
  position: absolute;
  left: 0px;
  bottom: 60px;
  width: calc(100% - 0px);
  height: 100px;
  background: 'none';
  overflow: hidden !important;
}

.hue-picker, .alpha-picker {
  margin: 0 !important;
  width: 100% !important;
  height: 50% !important;
  overflow: hidden !important;
}

.hue-horizontal > div {
  display: none;  
  visibility: hidden;
}

.alpha-picker > div > div > div > div {
  display: none;  
  visibility: hidden;
}
